<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader v-if="mitarbeiter">
          <CIcon name="cil-calendar"/>
          Eine neue Abwesenheit / Krankheit anlegen für {{mitarbeiter.name}}
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="newAbwesenheit()">

              <AbwesenheitText v-model="name" formLabel="Name"/>
              <CSelect
                label="Art"
                :value.sync="art"
                :isValid="true"
                :options="statusArt"
                class="mt-2"
              />
              <AbwesenheitDatum v-model="datum" formLabel="Datum der Abwesenheit / Krankheit" class="mt-3"/>
              <AbwesenheitNumber v-model="dauer" formLabel="Dauer der Abwesenheit / Krankheit"/>

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Abwesenheit / Krankheit anlegen</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import AbwesenheitText from '@/components/FormElements/Text'
import AbwesenheitNumber from '@/components/FormElements/Number'
import AbwesenheitDatum from '@/components/FormElements/Date'

export default {
  name: 'MitarbeiterNew',
  components: {
    FormError,
    AbwesenheitText,
    AbwesenheitNumber,
    AbwesenheitDatum
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      name: '',
      art: 'Urlaub',
      datum: '',
      dauer: 8.00,
      mitarbeiter: null,
      formError: null,
      statusArt: [
        { value: 'Urlaub', label: 'Urlaub' },
        { value: 'Krankheit', label: 'Krankheit' },
        { value: 'Anderes', label: 'Anderes' }
      ]
    }
  },
  mounted () {
    this.loadMitarbeiter()
    this.setDefaults()
  },
  methods: {
    setDefaults () {
      this.name = ''
      this.art = 'Urlaub'
      this.datum = ''
      this.dauer = 8
      this.formError = null
    },
    loadMitarbeiter () {
      Vue.axios.get('/mitarbeiter/get/user/' + this.uuid)
        .then((response) => {
          response.data = response.data[0]
          this.mitarbeiter = response.data
        })
    },
    newAbwesenheit () {
      Vue.axios.post('/abwesenheit/post', {
        mitarbeiter: this.uuid,
        name: this.name,
        art: this.art,
        datum: this.datum,
        dauer: this.dauer
      }).then((response) => {
        this.$router.push({ path: '/abwesenheiten/uebersicht/' + this.uuid })
        this.$snotify.success('Die neue Abwesenheit / Krankheit wurde angelegt.', {
          position: 'rightTop',
          timeout: 4000
        })
        this.setDefaults()
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
